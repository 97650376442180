import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Viewer } from '@react-pdf-viewer/core';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import Web3 from 'web3';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import axios from 'axios';
import ABI from '../../utils/ABI.json';
import { AiOutlineFacebook, AiOutlineTwitter } from 'react-icons/ai'
import { ImPinterest2, ImTumblr } from 'react-icons/im'
import truncateEthAddress from 'truncate-eth-address'
const ContractAddress = '0x290350a5c68cf607079e3fae73ba4975ed080f1a';
const ContractABI = ABI;
const web3Instance = new Web3('https://goerli.infura.io/v3/1a4e0b16869a40c4bc63f0d86384b197');

export default function NftDetails() {
    const params = useParams();
    const nftId = parseInt(params.id); // Convert nftId to number
    const [nft, setNFT] = useState(null);
    const [tokenName, setTokenName] = useState();
    const [tokenSymbol, setTokenSymbol] = useState();
    const [pdfBlobUrl, setPdfBlobUrl] = useState(null); // State to hold the Blob URL
    const loadNFTs = async () => {
        try {

            if (window.ethereum) {
                //await window.ethereum.enable();

                // Get the contract instance
                const contract = new web3Instance.eth.Contract(ContractABI, ContractAddress);

                // Get the total number of NFTs
                //   const totalNFTs = await contract.methods.totalSupply().call();
                const tokenname = await contract.methods.name().call();
                const tokensymbol = await contract.methods.symbol().call();
                setTokenName(tokenname)
                setTokenSymbol(tokensymbol)
                // Retrieve each NFT by its token ID
                const tokenURI = await contract.methods.tokenURI(nftId).call();
                // Replace the image URL prefix
                const nftURL = tokenURI.replace('ipfs://', 'https://ipfs.io/ipfs/');
                const url = `https://cors-anywhere.herokuapp.com/${nftURL}`;

                // Fetch the metadata from the complete URL
                const response = await fetch(url);
                const metadata = await response.json();
                const nftImage = metadata.image.replace('ipfs://', 'https://ipfs.io/ipfs/');
                const nft = {
                    id: nftId,
                    name: metadata.name,
                    description: metadata.description,
                    image: nftImage,
                    pdf: metadata.pdf,
                    attributes: metadata.attributes,
                };
                setNFT(nft)
            }
        } catch (error) {
            console.error('Error fetching NFTs:', error);
        }
    };
    // Create new plugin instance
    const defaultLayoutPluginInstance = defaultLayoutPlugin();

    useEffect(() => {
        if (nftId) {
            loadNFTs();
        }
        //eslint-disable-next-line
    }, [nftId]);

    useEffect(() => {
        if (nft?.pdf) {
            // Fetch the PDF file and create a Blob URL
            axios
                .get(`https://cors-anywhere.herokuapp.com/${nft.pdf}`, { responseType: 'blob' })
                .then((response) => {
                    const blobUrl = URL.createObjectURL(response.data);
                    setNFT({ ...nft, pdf: blobUrl });
                })
                .catch((error) => {
                    console.error('Error fetching PDF:', error);
                });
        }
    }, [nft]);
    return (
        <div className='container-fluid'>
            <div className="container">
                <div className='row py-5'>
                    <div className='col-md-7'>
                        {nft?.pdf ? (
                            <div
                                style={{
                                    height: '40rem',
                                }}
                            >
                                <Viewer fileUrl={nft?.pdf} plugins={[defaultLayoutPluginInstance]} />
                            </div>
                        ) : (
                            <img src={nft?.image} alt={nft?.name} className='img-fluid' />
                        )}
                    </div>
                    <div className='col-md-5'>
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                            <Breadcrumb.Item href="#">
                                {nft?.name}
                            </Breadcrumb.Item>
                        </Breadcrumb>
                        <h3>{nft?.name}</h3>
                        <p className='mb-5'>{nft?.description}</p>
                        <button className='btn btn-outline-secondary fw-bold'>ADICIONAR MINHA WALLET</button>
                        <hr />
                        <h4>Blockchain Details</h4>
                        <div className='row'>
                            <div className="col-md-6">
                                <strong>Token Name</strong>
                            </div>
                            <div className="col-md-6 float-end">
                                {tokenName}({tokenSymbol})
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-md-6">
                                <strong>Contract Address</strong>
                            </div>
                            <div className="col-md-6 float-end">
                                <a href="https://goerli.etherscan.io/address/0x290350a5c68cf607079e3fae73ba4975ed080f1a" target='_blank' rel='noreferrer' className='text-primary'>{truncateEthAddress('0x290350a5c68cf607079e3fae73ba4975ed080f1a')}</a>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-md-6">
                                <strong>Token ID</strong>
                            </div>
                            <div className="col-md-6 float-end">
                                {nftId}
                            </div>
                        </div>

                        <div className='row'>
                            <div className="col-md-6">
                                <strong>Token Standard</strong>
                            </div>
                            <div className="col-md-6 float-end">
                                ERC-271
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-md-6">
                                <strong>Chain</strong>
                            </div>
                            <div className="col-md-6 float-end">
                                Goerli
                            </div>
                        </div>
                        <div className="share border-top d-flex align-items-center mt-5">
                            <strong>Partilhar no:</strong>
                            <ul className='nav'>
                                <li><AiOutlineFacebook className='fs-3 m-2' /></li>
                                <li><AiOutlineTwitter className='fs-3 m-2' /></li>
                                <li><ImPinterest2 className='fs-3 m-2' /></li>
                                <li><ImTumblr className='fs-3 m-2' /></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
