import React from 'react'
import { Link } from 'react-router-dom'

export default function NftCard({ nft }) {
    return (
        <Link to={`/nft/${nft.id}`}>
            <div className="p-3">
                <img
                    src={nft.image}
                    alt={`NFT ${nft.id}`}
                    className="nft-image"
                />
                <h5 className='nft-title'>{nft.name}</h5>
                <p className='nft-edition'>Edition: {nft.id}</p>
            </div>
        </Link>
    )
}
