import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';

export default function Header() {
    return (
        <div>
            <div className="top-bar container-fluid">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <nav className='nav'>
                                <li className='nav-item'>
                                    <a href="/" rel='noreferrer' className='nav-link'>QUEM SOMOS</a>
                                </li>
                                <li className='nav-item'>
                                    <a href="/" rel='noreferrer' className='nav-link'>CONTACTOS</a>
                                </li>
                                <li className='nav-item'>
                                    <a href="/" rel='noreferrer' className='nav-link'>AS NOSSAS LOJAS</a>
                                </li>
                            </nav>
                        </div>
                        <div className="col-md-2 ms-auto">
                            <form>
                                <input className='form-control search' type="text" placeholder="Procurar por..." />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Navbar bg="white" expand="lg" className='container-fluid border-bottom'>
                <div className="row w-100">
                    <div className="col-md-4">
                        <Navbar.Brand href="/" className='ms-5'>
                            <Link to="/"> <img src='/images/logoExtenso.svg' alt='logo' className='logo' /></Link>
                        </Navbar.Brand>
                    </div>
                    <div className="col-md-6">
                        <div>
                            <Navbar.Toggle aria-controls="basic-navbar-nav" />
                            <Navbar.Collapse id="basic-navbar-nav">
                                <Nav className="ms-5">
                                    <Nav.Link href="#home">HOME</Nav.Link>
                                    <Nav.Link href="#link">LIVROS</Nav.Link>
                                    <Nav.Link href="#link">MOEDAS</Nav.Link>
                                    <Nav.Link href="#link">NOVIDADES</Nav.Link>
                                    <Nav.Link href="#link">CATÁLOGO</Nav.Link>
                                    <Nav.Link href="#link">GRANDES DESCONTOS</Nav.Link>
                                </Nav>
                            </Navbar.Collapse>
                        </div>
                    </div>
                </div>
            </Navbar>
        </div>
    )
}
