import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

export default function Footer() {
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 6
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 6
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    return (
        <footer>
            <div className='container-fluid'>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-10 border-top">
                            <Carousel responsive={responsive}>
                                <img src="/images/clients/1.png" alt="1" />
                                <img src="/images/clients/2.png" alt="2" />
                                <img src="/images/clients/3.png" alt="3" />
                                <img src="/images/clients/4.png" alt="4" />
                                <img src="/images/clients/5.png" alt="5" />
                                <img src="/images/clients/6.png" alt="6" />
                            </Carousel>
                        </div>
                    </div>
                </div>
                <div className="row border-top py-5">
                    <div className="col-md-2">
                        <h5 className="widget-title">UNIVERSO INCM</h5>
                        <ul className="nav-list ps-0">
                            <li><a href="/" rel="noreferrer" className="nav-link">Imprensa Nacional-Casa da</a></li>
                            <li><a href="/" rel="noreferrer" className="nav-link">Moeda</a></li>
                            <li><a href="/" rel="noreferrer" className="nav-link">Imprensa Nacional</a></li>
                            <li><a href="/" rel="noreferrer" className="nav-link">Diário da República</a></li>
                            <li><a href="/" rel="noreferrer" className="nav-link">Contrastarias</a></li>
                            <li><a href="/" rel="noreferrer" className="nav-link">Museu Casa da Moeda</a></li>
                        </ul>
                    </div>
                    <div className="col-md-2">
                        <h5 className="widget-title">CONTACTE-NOS</h5>
                        <ul className="nav-list ps-0">
                            <li><a href="/" rel="noreferrer" className="nav-link">Imprensa Nacional-Casa da</a></li>
                            <li><a href="/" rel="noreferrer" className="nav-link">Moeda</a></li>
                            <li><a href="/" rel="noreferrer" className="nav-link">Imprensa Nacional</a></li>
                            <li><a href="/" rel="noreferrer" className="nav-link">Diário da República</a></li>
                            <li><a href="/" rel="noreferrer" className="nav-link">Contrastarias</a></li>
                            <li><a href="/" rel="noreferrer" className="nav-link">Museu Casa da Moeda</a></li>
                        </ul>
                    </div>
                    <div className="col-md-2">
                        <h5 className="widget-title">APOIO AO CLIENTE</h5>
                        <ul className="nav-list ps-0">
                            <li><a href="/" rel="noreferrer" className="nav-link">Imprensa Nacional-Casa da</a></li>
                            <li><a href="/" rel="noreferrer" className="nav-link">Moeda</a></li>
                            <li><a href="/" rel="noreferrer" className="nav-link">Imprensa Nacional</a></li>
                            <li><a href="/" rel="noreferrer" className="nav-link">Diário da República</a></li>
                            <li><a href="/" rel="noreferrer" className="nav-link">Contrastarias</a></li>
                            <li><a href="/" rel="noreferrer" className="nav-link">Museu Casa da Moeda</a></li>
                        </ul>
                    </div>
                    <div className="col-md-2">
                        <h5 className="widget-title">INFORMAÇÃO LEGAL</h5>
                        <ul className="nav-list ps-0">
                            <li><a href="/" rel="noreferrer" className="nav-link">Imprensa Nacional-Casa da</a></li>
                            <li><a href="/" rel="noreferrer" className="nav-link">Moeda</a></li>
                            <li><a href="/" rel="noreferrer" className="nav-link">Imprensa Nacional</a></li>
                            <li><a href="/" rel="noreferrer" className="nav-link">Diário da República</a></li>
                            <li><a href="/" rel="noreferrer" className="nav-link">Contrastarias</a></li>
                            <li><a href="/" rel="noreferrer" className="nav-link">Museu Casa da Moeda</a></li>
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <h5 className="widget-title">SUBSCREVA A NOSSA NEWSLETTER</h5>
                        <input className="form-control" type='text' style={{ width: '300px' }} placeholder="Email" />
                        <button class="btn btn-outline-secondary mt-2" style={{ width: '300px' }}>Inscreva-se</button>
                    </div>
                </div>
            </div>
        </footer>
    )
}
